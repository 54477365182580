import axios from 'axios'
import {Message} from 'element-ui'
var preUrlOld = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://ers-user.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://user.1renshi.com";
    } else {
        return "https://ers-user.test-01.54test.cn";
    }
}()
var preUrlHome = function () {
    if (document.domain.includes("ers-welfare-mall.test-01.54test.cn")) {
        return "https://ers-official.test-01.54test.cn";
    } else if(document.domain.startsWith("mall.1renshi.com")) {
        return "https://www.1renshi.com";
    } else {
        return "https://ers-official.test-01.54test.cn";
    }
}()

function GetTokenFromCookie(name) {
    let token;
    let strcookie = document.cookie;//获取cookie字符串
    let arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (let i = 0; i < arrcookie.length; i++) {
        let arr = arrcookie[i].split("=");
        if (arr[0] == name && arr[1] != "") {
            token = arr[1];
        }
    }
    if(token){
        token = 'Bearer ' + token;
    }
    return token;
}

// 校验token
function getToken() {
    let token = GetTokenFromCookie("pcToken");
    return token;
}

export const userLink = preUrlOld;
export const homeLink = preUrlHome;
export const cookie = GetTokenFromCookie("pcToken");;

export const request = (config)=>{
    const instance = axios.create({
        // process.env.VUE_APP_API_URL 打包编译时对应API接口地址
        baseURL: process.env.VUE_APP_API_URL,
        timeout: '10000'
    });
    // 请求拦截
    instance.interceptors.request.use(config => {
        // 判断有无token
        if(getToken()){
            config.headers.authorization = getToken();
        }
        return config
    })

    // 响应拦截
    instance.interceptors.response.use(response =>{
        const data = response.data
        switch (data.code) {
            case 401:
                //Message.error('登录失效，请重新登录');
                // setTimeout(() => {
                //     window.open(`${preUrlOld}/Account/Login`, '_blank')
                // }, 500);
                return data
            default:
        return data
}
    })
    return instance(config)
}


