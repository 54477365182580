import $ from "jquery";
import "../slider2/css/slide-edit.css";
import "../slider2/js/jquery.lgyslide.js";
import "../js/jquery.cookie.min.js";
import "../js/jquery.qrcode.min.js"
import "../js/jquery.signalR-2.4.0.min.js"

var busapiUrl = "";
var userApiUrl = "";
var homeApiUrl = "";
(function () {
    if (document.domain.includes("1renshi.com")) {
        busapiUrl = "https://busapi.1renshi.com";
        userApiUrl = "https://user.1renshi.com";
        homeApiUrl="https://www.1renshi.com";
    } else if(document.domain.includes("localhost")) {
        busapiUrl = "https://api.ers-business.test-01.54test.cn";
        userApiUrl = "https://ers-user.test-01.54test.cn";
        homeApiUrl="https://ers-official.test-01.54test.cn";
    } else {
        busapiUrl = "https://api.ers-business.test-01.54test.cn";
        userApiUrl = "https://ers-user.test-01.54test.cn";
        homeApiUrl="https://ers-official.test-01.54test.cn";
    }
})();
var mobilePhoneReg = /^1[3-9]\d{9}$/; //手机正则
var phoneVestNumberReg = /^1[2-9]\d{9}$/; // 手机可以输入12xxx的正则 允许输入马甲账号
var pwdReg = /^(?:(?=.*[0-9].*)(?=.*[A-Za-z].*)(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、].*))[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、0-9A-Za-z]{8,16}$/;//密码正则
var isClickCode=true; // 验证码是否可点
var loop=null; // 倒计时
var getCount=0;
// 数据库连接对象
let conn = $.connection(userApiUrl+'/WxLoginConnection1');

var loginCom=function() {
    let that=this;
    this.init = ()=>{
        // 切换tab
        $('.lb-main-left .tab-item').click(function() {
            let index=$(this).index();
            $(this).addClass('tab-item-cur').siblings().removeClass('tab-item-cur');
            if(index==1) {
                $('#login-in-phone').hide();
                $('#login-in-password').show();
            } else {
                $('#login-in-phone').show();
                $('#login-in-password').hide();
            }
        })
        // 获取验证码
        $('.get-code').click(function() {
            let mobileDataDIv=$(this).parent().parent().find('.mobile-data');
            let mobile=mobileDataDIv.val().trim();
            console.log(mobileDataDIv,mobile,'mobile');
            if(!isClickCode) return
            if(!mobile) {
                return toastBox('请输入手机号码');
            } else if(!mobilePhoneReg.test(mobile)) {
                return toastBox('请输入正确的手机号码');
            }
            console.log(1111);
            $(this).parent().append('<div class="imgscode" style="position: absolute;"></div>');
            getSlidingImg(mobile,mobileDataDIv)
        })
        // 关闭按钮
        $('.common-header .icon-close').click(function() {
            let dialogDiv=$(this).parent().parent()
            dialogDiv.hide();
            $('.covering-layer').hide();
            $(".imgscode").hide();
            resetAll();
        })
        // 刷新
        $(document).on('click','.headerRefreshBox',function() {
            CreateQrCode()
        })
        // 监听是否在滑块区域
        document.addEventListener("click",function(event) {
            if(event.target.closest('.get-code')) {
                console.log(1);
            } else if(event.target.closest('.imgscode')) {
                console.log(22);
            } else if(event.target.closest('.icon-push')) {
                console.log(333);
            } else {
                $(".imgscode").remove();
            } 
        },false)
        //连接断开的方法
        conn.disconnected(function () {
            // alert("连接中断");
        });
        conn.error(function (data) {
        // 连接发生错误时候触发
        });
        // 接受服务器的推送
        conn.received(function (data) {
            ReceivedDate(data);
        })
        // 回车登录
        $('#slideCode,#slideMobile').bind('keyup',function(event) {
            if(event.keyCode==13) {
                loginSlideCode();
            }
        })
        $('#accountMobile,#accountPassword').bind('keyup',function(event) {
            if(event.keyCode==13) {
                loginAccount();
            }
        })
        $('#resetMobile,#resetCode,#resetPassword1,#resetPassword2').bind('keyup',function(event) {
            if(event.keyCode==13) {
                that.resetPassword();
            }
        })
        $('#wxMobile,#wxCode').bind('keyup',function(event) {
            if(event.keyCode==13) {
                that.loginWx();
            }
        })
    }
    // 打开登录弹窗
    this.openLoginDialog = ()=>{
        $('.login-box').show();
        $('.covering-layer').show();
        $('.password-data').attr('type','password');
        CreateQrCode();
    }
    this.loginIn = ()=>{
        let index=$('.lb-main-left .tab-item-cur').attr("data-type");
        if(index==1) {
            loginAccount(); // 账户密码登录
        } else {
            loginSlideCode(); // 滑块验证码登录
        }
    }
    // 重置密码-提交
    this.resetPassword = ()=>{
        let mobile=$('#resetMobile').val().trim();
        let code=$('#resetCode').val();
        let password1=$('#resetPassword1').val();
        let password2=$('#resetPassword2').val();
        if(!mobilePhoneReg.test(mobile)) {
            return toastBox('请输入正确的手机号')
        } else if(code.length===0) {
            return toastBox('请输入验证码');
        }else if (!pwdReg.test(password1)) {
            return toastBox('密码格式为8~16位数字、字母、特殊字符组合')
        } else if (password1 !== password2) {
            return toastBox('两次输入的密码不一致')
        }
        $.ajax({
            url:`${busapiUrl}/User/ResetPwdByMobile`,
            type:'put',
            async:false,
            dataType: "json",
            contentType: "application/json",
            data:JSON.stringify({
                mobile:mobile,
                redCode:code,
                pwd:password2
            }),
            success:function(result){
                if(result.code==200&&result.isSuccess) {
                    setTimeout(() => {
                        $('.reset-password-box').hide();
                        $('.login-box').show(); 
                        resetAll();
                    }, 500);
                }
                toastBox(result.msg)
            }
        })
    }
    // 微信扫码后未绑定需登录
    this.loginWx = ()=>{
        let mobile=$('#wxMobile').val();
        let code=$('#wxCode').val();
        let openId=$('#hidOpenId').val();
        if(!mobile) {
            return toastBox('请输入手机号码');
        } else if(!mobilePhoneReg.test(mobile)) {
            return toastBox('请输入正确的手机号码');
        } else if(code.length===0) {
            return toastBox('请输入验证码');
        }
        $.ajax({
            url: `${userApiUrl}/Enterprise/PcLogin`,
            type: 'post',
            data: {
                phone: mobile,
                code: code,
                openid: openId
            },
            success: function (result) {
                if (result.code === 1) {
                    loginMobile(mobile, code);
                } else {
                    toastBox(result.msg)
                }
            }
        })
    }
    // 是否显示密码
    this.isShowPassword = ()=>{
        let type=$('#accountPassword').attr('type');
        if(type=='password') {
            $('#accountPassword').attr('type','text');
            $('.li-icon-eye').addClass('li-icon-eye-open');
        } else {
            $('#accountPassword').attr('type','password');
            $('.li-icon-eye').removeClass('li-icon-eye-open');
        }
    }
    // 忘记密码
    this.openResetPassword = ()=>{
        $('.reset-password-box').show();
        $('.login-box').hide();
        resetAll();
    }
    // 返回登录
    this.returnLogin = ()=>{
        $('.li-input').val('');
        $('.reset-password-box').hide();
        $('.login-box').show();
    }
    // 获取滑块图片
    function getSlidingImg (mobile,mobileDataDIv) {
        $.ajax({
            url:`${busapiUrl}/Auth/SliderLogin/CheckMobileAndGetImage/${mobile}`,
            type:'get',
            async:false,
            success:function(result) {
                if(result.code&&!result.isSuccess) {
                    toastBox(result.msg);
                    $(".imgscode").remove();
                    return
                }
                if(result.code!=200) return
                $(".imgscode").show();
                $(".imgscode").imgcode({
                    frontimg:result.data.cutImage,
                    backimg:result.data.modifyImage,
                    yHeight:result.data.positionY,
                    callback:async function(msg) {
                        let res=await verifySlidingImg(result.data.imgId,msg.xpos);
                        if(res.isSuccess) {
                            this.getsuccess();
                            setTimeout(() => {
                                $(".imgscode").remove();
                                mobileDataDIv.attr('data-imgid',result.data.imgId);
                                sendCode(mobile);
                                time();
                            }, 500);
                        } else {
                            this.getfail('验证失败')
                            setTimeout(() => {
                                getSlidingImg(mobile,mobileDataDIv)
                            }, 1000);
                        }
                    },
                    refreshcallback:function() {
                        // console.log('刷新');
                        getSlidingImg(mobile,mobileDataDIv)
                    }
                })
            }
        })
    }
    // 验证滑块
    function verifySlidingImg(imgId,positionX) {
        return new Promise((resolve,reject)=>{
            $.ajax({
                url:`${busapiUrl}/Auth/SliderLogin/ImageIsValid`,
                type:'post',
                async:false,
                dataType: "json",
                contentType: "application/json",
                data:JSON.stringify({
                    imgId:imgId,
                    positionX:parseInt(positionX),
                }),
                success:function(result) {
                    resolve(result)
                },
                fail:function(err) {
                    reject(err)
                }
            })
        })
        
    }
    // 发送验证码
    function sendCode(mobile) {
        $.ajax({
            url:`${busapiUrl}/Auth/Message/${mobile}`,
            type:'get',
            success:function(result) {}
        })
    }
    // 验证码倒计时
    function time() {
        isClickCode=false;
        if (loop) {
        clearInterval(loop)
        }
            var timeOut = 59;
        let i = 0
        loop = setInterval(function () {
        $('.get-code').text(timeOut - i + ' s')
        i++
        if (i === 60) {
            clearInterval(loop)
            isClickCode = true;
            $('.get-code').text('获取验证码');
        }
        }, 1000)
    }
    // 滑块验证码登录
    function loginSlideCode() {
        let mobile=$('#slideMobile').val();
        let code=$('#slideCode').val();
        let imgId=$('#slideMobile').attr('data-imgid');
        if(!mobile) {
            return toastBox('请输入手机号码');
        } else if(!mobilePhoneReg.test(mobile)) {
            return toastBox('请输入正确的手机号码');
        } else if(code.length===0) {
            return toastBox('请输入验证码');
        }
        $.ajax({
            url:`${busapiUrl}/Auth/Login/SliderLogin`,
            type:'post',
            async:false,
            dataType: "json",
            contentType: "application/json",
            data:JSON.stringify({
                imgId:imgId,
                code:code,
                terminalType:1
            }),
            success:function(result){
                loginSuccess(result);
            }

        })
    }
    // 账户密码登录
    function loginAccount() {
        let mobile=$('#accountMobile').val();
        let password=$('#accountPassword').val();
        if (!phoneVestNumberReg.test(mobile)) {
            return toastBox('请输入正确的手机号')
        } else if (password.length === 0) {
            return toastBox('请输入密码')
        }
        $.ajax({
            url:`${busapiUrl}/Auth/Login/Account`,
            type:'post',
            async:false,
            dataType: "json",
            contentType: "application/json",
            data:JSON.stringify({
                mobile:mobile,
                password:password,
                terminalType:1
            }),
            success:function(result){
                loginSuccess(result);
            }
        })
    }
    // 无滑块,手机验证码登录
    function loginMobile(mobile,code) {
        $.ajax({
            url:`${busapiUrl}/Auth/Login/Mobile`,
            type:'post',
            async:false,
            dataType: "json",
            contentType: "application/json",
            data:JSON.stringify({
                mobile:mobile,
                code:code,
                terminalType:1
            }),
            success:function(result){
                loginSuccess(result);
            }
        })
    }
    // 登录成功后的操作
    function loginSuccess(result) {
        if(result.code==200&&result.isSuccess) {
            $('.login-box').hide();
            $('.covering-layer').hide();
            saveToken(result.data.token);
        } else {
            toastBox(result.msg)
        }
    }
    // 登录成功后存储
    function saveToken(token){
        $.ajax({
            url: `${userApiUrl}/Account/LoginHrAndSetCookie?token=${token}`,
            type: 'get',
            timeout: 5000,
            dataType: 'JSONP',  // 处理Ajax跨域问题
            data: { callback: "?" },
            xhrFields:{
                withCredentials:true
            },
            success: function (result) {
                if (result) {
                    window.location.reload();
                }
            }
        })
    }
    // 提示语
    function toastBox(text) {
        let toast = document.createElement('div');
        toast.style.padding='20px';
        toast.style.position='fixed';
        toast.style.top='50%';
        toast.style.left='50%';
        toast.style.transform='translate(-50%,-50%)';
        toast.style.backgroundColor='rgb(0,0,0)';
        toast.style.borderRadius='5px';
        toast.style.zIndex='999999999999';
        toast.style.opacity='0.7';
        toast.style.transition='all 2s';
        toast.innerHTML=`<p style="font-size:14px;color:#fff;">${text}</p>`;
        document.body.appendChild(toast);
    
        setTimeout(()=>{
            toast.style.opacity='0';
        },2000)
        toast.addEventListener("transitionend", () => {
            toast.remove()
        })
    }
    // 获取微信二维码
    function CreateQrCode() {
        console.log(1111);
        getCount = 0;
        $.ajax({
            url: `${userApiUrl}/Enterprise/GetQrCode`,
            type: 'post',
            async: false,
            success: function (res) {
                $('#divQrCode').html("");
                if (res.code === 0) {
                    bindUrl(res.qrUrl);
                    CheckStatus(res.sceneId) // 更新扫码状态
                } else {
                    $('#divQrCode').html(`<img class="headerRefreshBox" src="/refresh.png" />`);
                }
            }
        })
    }
    // 添加微信二维码
    function bindUrl(url) {
        jQuery('#divQrCode').qrcode({
            render: "canvas", //也可以替换为table
            width: 200,
            height: 200,
            text: url
        });
    }
    // 公众号已关注,判断后续
    function ReceivedDate(data){
        if (data !== '' && data != null) {
            try {
                var key = JSON.parse(data).key;
                var code = JSON.parse(data).code;
                getCount = 60;
                var conId = $("#hidConnId").val();
                if(code == '0') { // 已关注,已绑定
                    let mobile=JSON.parse(data).mobile;
                    let key=JSON.parse(data).key;
                    conn.stop();
                    loginMobile(mobile,key);
                } else if(code == '1') { // 已关注未绑定
                    $('#hidOpenId').val(JSON.parse(data).openId);
                    $('.login-box').hide();
                    $('.login-no-wx-box').show();
                    resetAll();
                } else { // 已登录,请退出 和 未关注
                    toastBox(JSON.parse(data).msg);
                }
            }
            catch {
                console.log(data);
            }
        }
    }
    // 发送订单ID与服务器连接
    function CheckStatus(sceneId) {
        conn.stop();
        $("#hidConnId").val(sceneId);
        //建立连接
        conn.start().done(function (data) {
            //你可以将send方法加到一个click事件中已适应你的业务逻辑
            $.ajax({
                type: "get",
                url: `${userApiUrl}/Account/SaveConnIdInfo?sceneId=${sceneId}&connectionId=${data.id}`,
                success: function (res) {
                    if (!res.success) {
                        toastBox("操作异常！");
                    }
                }
            });
        })
        //发送消息之前要判断连接状态,conn.state有4中状态
        //connecting: 0(正在连接),   connected: 1(正常连接),  reconnecting: 2（正在重连）,    disconnected: 4 （掉线了）
        if (conn.state == 1) {
            //发送消息
            conn.send(sceneId);
        }
        GetScanResult();
        setTimeout(function () {
            if (conn.state == 1) {
                //发送消息
                conn.send(sceneId);
            }
        }, 2000);
    }
    // 轮播请求
    function GetScanResult() {
        var sceneId = $("#hidConnId").val();
        getCount = getCount + 1;
        setTimeout(function () {
            $.ajax({
                type: "get",
                url: `${userApiUrl}/Account/GetScanResult?sceneId=${sceneId}`,
                success: function (res) {
                    if (res.code == 0) {
                        ReceivedDate(res.data);
                    }else {
                        if (getCount < 60) {
                            GetScanResult()
                        } else {
                            $('#divQrCode').html(`<img class="headerRefreshBox" src="/refresh.png" />`);
                        }
                    }
                }
            });
        }, 2000);
    }
    // 重置输入框
    function resetAll() {
        $('.li-input').val('');
        isClickCode = true;
        clearInterval(loop);
        $('.get-code').text('获取验证码');
    }
    return this
}
export {
    loginCom
}