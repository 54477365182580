<template>
  <div id="app" v-cloak>
    <Header />
    <el-main>
      <router-view/>
    </el-main>
    <Footer />
    <Login />
  </div>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue';
import Login from "./components/login.vue";
export default {
  components:{
    Header,
    Footer,
    Login
  }
}
</script>
<style lang="less">
.el-main{
  margin-top: 70px;
  padding:0 !important;
  overflow: hidden;
}
//样式初始化
@import url('assets/css/base.css');
// message的样式修改
.el-message{
  margin-top: 300px !important;
}

// v-cloak隐藏式加载代码数据
[v-cloak]{
  display: none;
}
.el-message{
  margin-top: 300px !important;
}
.confirm-dialog{
  border-radius: 10px !important;
  margin-top: 170px !important;
}
.confirm-dialog .el-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 !important;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    border-radius: 10px !important;
    z-index: 20002 !important;
    min-width: 380px;
    overflow: hidden;
    
}
.confirm-dialog .el-dialog__header{
    height: 43px;
    border-radius: 10px 10px 0 0  !important;
    /* background: linear-gradient(96deg, #40A9FF 0%, #597EF7 100%); */
    background: #0089CD;
    padding-top: 10px;
}
.confirm-dialog .el-dialog__header .el-dialog__headerbtn{
    font-size: 24px !important;
    top: 5px;
}
.confirm-dialog .el-dialog__header .el-dialog__close {
    color: white;
}

.confirm-dialog .el-dialog__title{
    color: white;
}
.confirm-dialog .el-dialog__body {
    padding: 30px 20px 5px;
}
// .confirm-dialog .el-dialog--center .el-dialog__body{
//   padding: 25px 0 !important;
// }
.confirm-dialog .dialog-footer .el-button--primary{
  color: #fff;
}
.input_box{
  .el-input__inner{
    border-radius: 18px 0 0 18px !important;
  }
}
.serch_btn{
  border-radius: 0 18px 18px 0 !important;
}
//滚动条的宽度
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #063b5a27;
  // background-color: #B4A0AB;
  border-radius: 5px;
}
</style>
