import $ from "jquery";
window.jQuery=$;
(function($) {	
	$.fn.imgcode = function(options) {
		// 初始化参数
		var defaults = {
			frontimg : "",
			backimg : "",
			refreshImg : "",
			getsuccess : "",
			getfail : "",
			callback : "", // 回调函数
			refreshcallback : "",
			yHeight : 1

		};
		var opts = $.extend(defaults, options);
		return this.each(function() {
					var $this = $(this);// 获取当前对象
					var html = 
							'<div class="code-img">'
							+ '<div class="code-img-con">'
							+ '<div class="code-mask"><img class="code-front-img" src="'
							+ opts.frontimg
							+ '"></div>'
							+ '<img class="code-back-img" src="'
							+ opts.backimg
							+ '"></div>'
							+ '<div class="code-push"><i class="icon-login-bg icon-w-25 icon-push">刷新</i><span class="code-tip"></span></div>'
							+ '</div>' + '<div class="code-btn">'
							+ '<div class="code-btn-img code-btn-m"><span class="sliderImgPuzzleIcon"></span></div>'
							+ '<span class="code-span">向右滑动滑块填充拼图</span>'
							+ '</div>';
					$this.html(html);
					$(".code-mask").css("margin-top",opts.yHeight+"px")
					$this.css("border", "1px solid transparent") // 边框颜色复原
					// 定义拖动参数
					var $divMove = $(this).find(".code-btn-img"); // 拖动按钮
					var $divWrap = $(this).find(".code-btn");// 鼠标可拖拽区域
					var mX = 0, mY = 0;// 定义鼠标X轴Y轴
					var dX = 0, dY = 0;// 定义滑动区域左、上位置
					var isDown = false;// mousedown标记
					if (document.attachEvent) {// ie的事件监听，拖拽div时禁止选中内容，firefox与chrome已在css中设置过-moz-user-select:
												// none; -webkit-user-select:
												// none;
						$divMove[0].attachEvent('onselectstart', function() {
							return false;
						});
					}

					// 鼠标移入移出滑动块控制悬浮样式和拼图显示
					$this.find(".code-btn-m").hover(function(){
						if ($(this).hasClass("code-btn-m-green")) {
							return
						}
						$this.css("border", "1px solid transparent")
						$(this).addClass("code-btn-m-hover").removeClass("code-btn-m-red")
						// $(".code-img").css("display","block");
					});
					$this.find(".code-btn-m").mousedown(function(){
						if ($(this).hasClass("code-btn-m-green")) {
							return
						}
						$(".code-img").css("display","block");
					});
					$this.find(".code-btn-m").mouseleave(function() {
						$(this).removeClass("code-btn-m-hover")
					})
					// 离开滑动框拼图消失
					$this.mouseleave(function(){
						// $(".code-img").css("display","none");
					});

					// 按钮拖动事件
					$divMove.unbind('mousedown').on({
						mousedown : function(e) {
							// 清除提示信息
							$this.find(".code-tip").html("");
							var event = e || window.event;
							mX = event.pageX;
							dX = $divWrap.offset().left;
							dY = $divWrap.offset().top;
							console.log("AAAA-鼠标点击-鼠标的mX：" + mX)
							console.log("BBBB-鼠标点击-滑动框-的dX：" + dX)
							console.log("CCCC-鼠标点击-滑动框-的dY：" + dY)
							isDown = true;// 鼠标拖拽启
							$(".code-img").show()
							$(".code-span").text("")
						}
					});
					// 刷新code码
					$this.find(".icon-push").unbind('click').click(function() {
						opts.refreshcallback();
					});
					// 鼠标点击松手事件
					$divMove.unbind('mouseup').mouseup(
						function(e) {
							console.log("GGGG-鼠标松开之后--拼图的offsetLeft " +   $this.find(".code-mask").offset().left)
							console.log("HHHH-鼠标松开之后--拼图的offsetLeft-滑块dX - 1 " + ($this.find(".code-mask").offset().left - dX - 1) )
							var lastX = $this.find(".code-mask").offset().left - dX - 1;
							isDown = false;// 鼠标拖拽启
							$divMove.removeClass("active");
							returncode(lastX);
						});
					// 滑动事件
					$divWrap.mousemove(function(event) {
						var event = event || window.event;
						var x = event.pageX;// 鼠标滑动时的X轴
						var y = event.pageY;// 鼠标滑动时的Y轴
						if (isDown) {
							console.log("DDDD-x鼠标滑动-X轴: " + x)
							console.log("EEEE-鼠标范围：大于dX " + dX + "  小于dX+滑动框宽度-50  " +  (dX+$(this).width()- 50))
							if (x <= dX+1 || x >= dX + 318-1 || y <= dY+1 || y >= dY + 47-1) {
								$divMove.mouseup()
							}
							else if (x > (dX) && x < dX + $(this).width()- 50) { 
								console.log("FFFF-滑动块和拼图移动到鼠标x-滑动框x值 " + (x-dX) )
								$divMove.css({ "left" : (x - dX) + "px"});// div动态位置赋值
								$this.find(".code-mask").css({"left" : (x - dX ) + "px"});
							}
						}
					});
					// 返回坐标系
					function returncode(xpos) {
						opts.callback({
							xpos : xpos
						});
					}
					// 验证数据
					// function checkcode(code) {
					// 	var iscur = true;
					// 	// 模拟ajax
					// 	setTimeout(function() {
					// 		if (iscur) {
					// 			checkcoderesult(1, "验证通过");
					// 			$this.find(".code-k-div").remove();
					// 			opts.callback({
					// 				code : 1000,
					// 				msg : "验证通过",
					// 				msgcode : "23dfdf123"
					// 			});
					// 		} else {
					// 			$divMove.addClass("error");
					// 			checkcoderesult(0, "验证不通过");
					// 			opts.callback({
					// 				code : 1001,
					// 				msg : "验证不通过"
					// 			});
					// 			setTimeout(function() {
					// 				$divMove.removeClass("error");
					// 				$this.find(".code-mask").animate({
					// 					"left" : "0px"
					// 				}, 200);
					// 				$divMove.animate({
					// 					"left" : "10px"
					// 				}, 200);
					// 			}, 300);
					// 		}
					// 	}, 500)
					// }
					// 刷新图标
					opts.refreshImg = function(data) {
						// console.log(data)
						$this.find(".code-img-con .code-front-img").attr("src",	data.frontImg);
						$this.find(".code-img-con .code-back-img").attr("src", data.backGoundImg);
					}
					// 验证成功
					opts.getsuccess = function() {
						checkcoderesult(1, "验证通过");

					}
					// 验证失败
					opts.getfail = function(txt) {
						$divMove.addClass("error");
						checkcoderesult(0, txt);
						setTimeout(function() {
							$divMove.removeClass("error");
							$this.find(".code-mask").animate({
								"left" : "0px"
							}, 200);
							$divMove.animate({
								"left" : "0px"
							}, 200);
							$(".code-span").text("向右滑动滑块填充拼图")
							// 复原颜色
							$this.css("border", "1px solid transparent")
							$this.find(".code-btn").css("background-color", "#f5f6f7")
							$(".code-btn-m").removeClass("code-btn-m-red").addClass("code-btn-m-default")
						}, 400);
					}
					// 验证结果
					function checkcoderesult(i, txt) {
						// 失败
						if (i == 0) {
							// 变红
							$this.find(".code-tip").addClass("code-tip-red").removeClass("code-tip-green")
							$this.css("border", "1px solid #f57a7a")
							$this.find(".code-btn").css("background-color", "#fce1e1")
							$this.find(".code-btn-m").removeClass().addClass("code-btn-m code-btn-m-red")
						}
						// 成功 
						else {
							// 变绿
							$this.find(".code-tip").addClass("code-tip-green").removeClass("code-tip-red")
							$this.css("border", "1px solid #52ccba")
							$this.find(".code-btn").css("background-color", "#d2f4ef")
							$this.find(".code-btn-m").removeClass().addClass("code-btn-m code-btn-m-green")
							$divMove.unbind('mouseover').unbind('mouseleave') // 禁止操作
							$(".code-btn-m").css("pointer-events","none")
						}
						$this.find(".code-tip").html(txt);
					}
				})
	}
})(jQuery);