<template>
    <div class="layout-login">
        <div class="login-box" style="display:none;">
            <header class="common-header">
                <img src="~/assets/img/login/logo_s.png" alt="logo" />
                <div class="describe">让复杂的人事工作简单化</div>
                <i class="icon-close"></i>
            </header>
            <div class="lb-main">
                <div class="lb-main-left">
                    <div class="tab-list">
                        <div class="tab-item tab-item-cur" data-type="0">手机登录</div>
                        <div class="tab-item" data-type="1">密码登录</div>
                    </div>
                    <div id="login-in-phone" class="login-in-box">
                        <p class="lip-remind">验证即登录，未注册将自动免费创建本站账号</p>
                        <div class="li-input-box" style="margin-top:10px;">
                            <input id="slideMobile" class="li-input mobile-data" placeholder="请输入手机号" type="text" autocomplete="off" maxlength="11" />
                        </div>
                        <div class="li-input-box li-input-box-code">
                            <input id="slideCode" class="li-input li-input-code code-data" placeholder="请输入验证码" type="text" autocomplete="off" maxlength="6" />
                            <div class="line"></div>
                            <div class="get-code">获取验证码</div>
                        </div>
                    </div>
                    <div id="login-in-password" class="login-in-box" style="display:none;padding-top:5px;">
                        <div class="li-input-box">
                            <input id="accountMobile" class="li-input" placeholder="请输入手机号" type="text" autocomplete="off" />
                        </div>
                        <div class="li-input-box">
                            <input id="accountPassword" class="li-input password-data" placeholder="请输入密码" type="text" autocomplete="off" />
                            <i class="li-icon-eye" @click="isShowPasswordCom()"></i>
                        </div>
                        <div class="forget-box">
                            <span class="forget-text" @click="openResetPasswordCom()">忘记密码</span>
                        </div>
                    </div>
                    <div class="login-btn" @click="loginInCom()">登录</div>
                    <div class="agree-box">登录即同意<a class="web-agree" :href="preUrlHome+'/useragreement.html'" target="_blank"> 《壹人事网站用户协议政策》</a></div>
                    <div class="login-explain">本网站仅支持企业HR及相关工作的人员注册</div>
                </div>
                <div class="lb-main-line"></div>
                <div class="lb-main-right">
                    <div class="login-in-wx-tit">扫码登录</div>
                    <div id="divQrCode" class="login-in-wx-qrcode">
                    </div>
                    <div class="login-in-wx-sy">使用 <span>微信</span> 扫二维码登录</div>
                    <div class="login-in-wx-sc">首次扫码将自动免费注册新账号</div>
                </div>
            </div>
        </div>
        <div class="reset-password-box" style="display:none;">
            <header class="common-header">
                <div class="tit">重置密码</div>
                <i class="icon-close"></i>
            </header>
            <div class="main" style="position:relative;">
                <div class="li-input-box" style="margin-top:30px;">
                    <input id="resetMobile" class="li-input mobile-data" placeholder="请输入手机号" type="text" autocomplete="off" />
                </div>
                <div class="li-input-box li-input-box-code">
                    <input id="resetCode" class="li-input li-input-code" placeholder="请输入验证码" type="text" autocomplete="off" maxlength="6" />
                    <div class="line"></div>
                    <div class="get-code">获取验证码</div>
                </div>
                <div class="li-input-box">
                    <input id="resetPassword1" class="li-input" placeholder="密码为8~16位数字、字母、特殊字符组合" type="text" autocomplete="off" />
                </div>
                <div class="li-input-box">
                    <input id="resetPassword2" class="li-input" placeholder="密码确认" type="text" autocomplete="off" />
                </div>
                <div class="login-btn" style="margin:45px 0 16px;" @click="resetPasswordCom()">提交</div>
                <div class="back-button" @click="returnLoginCom()">返回登录</div>
            </div>
        </div>
        <div class="login-no-wx-box" style="display:none;">
            <header class="common-header">
                <div class="tit">手机绑定</div>
                <i class="icon-close"></i>
            </header>
            <div class="main" style="position:relative;">
                <p class="lip-remind">微信首次扫码，需进行手机绑定</p>
                <div class="li-input-box" style="margin-top:10px;">
                    <input id="wxMobile" class="li-input mobile-data" placeholder="请输入手机号" type="text" autocomplete="off" maxlength="11" />
                </div>
                <div class="li-input-box li-input-box-code">
                    <input id="wxCode" class="li-input li-input-code code-data" placeholder="请输入验证码" type="text" autocomplete="off" maxlength="6" />
                    <div class="line"></div>
                    <div class="get-code">获取验证码</div>
                </div>
                <div class="login-btn" style="margin:50px 0 25px;" @click="loginWxCom()">登录</div>
            </div>
        </div>
        <div class="covering-layer" style="display:none;"></div>
        <input type="hidden" id="hidConnId" />
        <input type="hidden" id="hidOpenId" />
    </div>
</template>

<script>
import {loginCom} from "../assets/js/login"
export default {
    data() {
        return {}
    },
    created() {},
    mounted() {
        new loginCom().init();
        this.$bus.$on('openLoginDialogCom',()=>{
            new loginCom().openLoginDialog();
        })
    },
    methods:{
        loginInCom() {
            new loginCom().loginIn();
        },
        isShowPasswordCom() {
            new loginCom().isShowPassword();
        },
        openResetPasswordCom() {
            new loginCom().openResetPassword();
        },
        returnLoginCom() {
            new loginCom().returnLogin();
        },
        closeWebAgreeCom() {
            new loginCom().closeWebAgree();
        },
        resetPasswordCom() {
            new loginCom().resetPassword();
        },
        loginWxCom() {
            new loginCom().loginWx();
        },
        updateWxCode() {
            console.log(222);
        }
    }
}
</script>

<style lang="less" scoped>
.common-header {
    padding: 0 20px 0 30px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 62px;
    border-bottom: 1px solid #EEEEEE;
}
.common-header img {
    width: 90px;
    height: 28px;
}
.common-header .describe {
    flex: 1;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #222222;
}
.common-header .icon-close {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url("../assets/img/login/icon_X.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
}
.common-header .tit {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: #222222;
}
.layout-login .main {
    padding: 0 50px;
}
/* 遮盖层 */
.covering-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}
/* 登录 */
.login-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 700px;
    height: 420px;
    background: #fff;
    border-radius: 16px;
    z-index: 10000000;
}
.lb-main {
    display: flex;
}
.lb-main-left {
    padding: 0 40px;
    width: 400px;
    box-sizing: border-box;
}
.lb-main-left .tab-list {
    display: flex;
    align-items: center;
    margin: 24px 0 16px;
    font-size: 18px;
    font-weight: 400;
    color: #909399;
}
.lb-main-left .tab-item {
    margin-right: 30px;
    height: 30px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}
.lb-main-left .tab-item-cur {
    color: #222222;
    border-color: #5688FF;
}
.login-in-box {
    position: relative;
    height: 170px;
}
.lip-remind {
    font-size: 12px;
    font-weight: 400;
    color: #71757E;
}
.li-input-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 320px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #DDDDDD;
}
.li-input {
    padding: 0 16px;
    width: 100%;
    height: 100%;
    border-color: transparent;
}
.li-input-code {
    width: 213px;
}
.li-input-box .line {
    width: 1px;
    height: 20px;
    border-left: 1px solid #DDDDDD;
}
.li-input-box .get-code {
    width: 107px;
    font-size: 15px;
    font-weight: 400;
    color: #5688FF;
    text-align: center;
    cursor: pointer;
}
.li-icon-eye {
    margin-right: 16px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../assets/img/login/close-y.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
}
.li-icon-eye-open {
    background-image: url("../assets/img/login/open-y.png");
}
.login-btn {
    width: 320px;
    height: 44px;
    line-height: 44px;
    background: #5688FF;
    border-radius: 6px 6px 6px 6px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}
.agree-box {
    margin: 10px 0 6px;
    font-size: 14px;
    color: #222222;
    text-align: center;
}
.web-agree {
    color:#1370EB;
    cursor: pointer;
}
.login-explain {
    margin: auto;
    width: 282px;
    height: 25px;
    line-height: 25px;
    background: #FFF9EE;
    border-radius: 0px 0px 0px 0px;
    font-size: 13px;
    font-weight: 400;
    color: #FFBA4B;
    text-align: center;
}
.lb-main-line {
    width: 1px;
    height: 280px;
    margin-top: 30px;
    border-left: 1px solid #EEEEEE;
}
.lb-main-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    box-sizing: border-box;
}
.login-in-wx-tit {
    padding: 30px 0 12px;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
}
.login-in-wx-qrcode {
    width: 200px;
    height: 200px;
    cursor: pointer;
}
.login-in-wx-sy {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
}
.login-in-wx-sy>span {
    color: #5688FF;
}
.login-in-wx-sc {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #71757E;
}
.forget-box {
    width: 320px;
}
.forget-text {
    float: right;
    margin-top: 6px;
    font-size: 13px;
    font-weight: 400;
    color: #1370EB;
    cursor: pointer;
}
/* 重置密码 */
.reset-password-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 420px;
    height: 472px;
    background: #fff;
    border-radius: 16px;
    z-index: 10000000;
}
.back-button {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
/* 扫码微信未绑定 */
.login-no-wx-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 420px;
    height: 348px;
    background: #fff;
    border-radius: 16px;
    z-index: 10000000;
}
.login-no-wx-box .lip-remind {
    padding-top: 30px;
}
</style>
