<template>
    <div class="user-drawer">
        <!-- 新增企业 -->
        <div class="add-company dialog" v-if="$store.state.addCompany">
            <div class="dialog-head">
                <div class="head-title">新增企业</div>
                <div class="close-icon" @click="closeAddCompany"><img src="~/assets/img/header/close-drawer.png" /></div>
            </div>
            <div class="addcom-wrap">
                <div class="addcom-item" id="Logo" @click="editCompanyLogo">
                    <img class="logo" id="companyLogo" :src="companyImg" alt="">
                    <input class="uploadImg2" accept="image/jpeg,image/jpg,image/png" type="file" id="file2">
                    <!-- hover重新上传 -->
                    <div class="resetLogo-backg" style="display:none;">
                        <h4>重新上传</h4>
                    </div>
                </div>
                <div class="pic"><span>完善信息</span></div>
                <div class="addcom-item">
                    <div class="input-block">
                        <span style="color: #FF3D3D;padding-right: 8px;font-size: 14px">*</span>
                        <input id="companyNameValue" maxlength="30" type="text" name="title" placeholder="请输入与营业执照一致的企业全称" class="default-input">
                    </div>
                </div>
                <div class="addcom-item">
                    <div class="input-block">
                        <span style="color: #FF3D3D;padding-right: 8px;font-size: 14px">*</span>
                        <input id="shortCompanyNameValue" maxlength="30" type="text" name="title" placeholder="请输入公司简称（2-6个汉字）" class="default-input">
                    </div>
                </div>
                <div class="addcom-item">
                    <div class="input-block">
                        <span style="color: #FF3D3D;padding-right: 8px;font-size: 14px">*</span>
                        <input id="companyEmailValue" maxlength="30" type="text" name="title" placeholder="请输入正确的公司邮箱" class="default-input">
                    </div>
                </div>
                <div class="email-pic">请填写正确的公司邮箱，为了方便您接收消息和通知</div>
                <div class="addcom-btn-box">
                    <button type="button"  class="default-btn btn-primary" @click="closeAddCompany">取消</button>
                    <button type="button" class="default-btn btn-normal" @click="confirmNew">确定</button>
                </div>
            </div>        
        </div>

        <!-- 工作台选择公司弹窗 -->
        <div class="company" v-if="$store.state.chooseCompany">
            <div class="box-head">
                <div class="head-title">请选择您要登录的公司</div>
                <div class="close-icon" @click="$store.commit('closeCompany')"><img src="~/assets/img/header/close-drawer.png" /></div>
            </div>
            <div class="box-main">
            <!-- 公司信息 -->
                <ul class="company-box">
                    <li class="company-item" v-for="companyItem,index in userData.companyList" :key="index">
                    <div class="com-name" @click="workbench(companyItem,remindUrl)">
                            {{companyItem.companyName?companyItem.companyName:"null"}}
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    

        <!-- 弹框遮罩层   --> 
        <div class="mask" v-if="$store.state.addCompany||$store.state.chooseCompany"></div>
    </div>
</template>
<script>
import $ from 'jquery'
import {mapState } from 'vuex'
import {getReqUserInfo,getReqGoAuthUrl} from 'network/welfareMall_api.js';
export default {
    data(){
        return{
            defaultImg:require('../assets/img/header/tx.png'),//用户默认头像
            companyImg:require('../assets/img/header/pic.png'),//公司默认头像
            userData:{//获取的用户信息
                companyList:[],
                privateUserID:'',
                privateUserInfo:{},
                userID:'',
                userInfo:'',
                userType:'',
            },
           
            img_Url:
            "https://file.1renshi.com/FileHandler.ashx?module=article&type=image",
            //https://file.1renshi.com/FileHandler.ashx?module=article&type=image https://ers-file.test-01.54test.cn/FileHandler.ashx?module=article&type=image
            whereOpenAddCompany : true,//默认右侧打开
            isMobileOrEmail:false,//手机是false
            jumpLink: this.preUrlHome,
            jumpLoginLink:this.preUrlOld,
            communityLink:this.communityUrl,
            mobileReg: /^1(3|4|5|6|7|8|9)\d{9}$/,//手机号 正则
            //emailReg : /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
            emailReg : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }
    },
    computed:{
        // 映射state
        ...mapState(['useInfo', 'companyInfo', 'cartNum']),
    },
    created(){
        //console.log(this.useInfo)
        this.userData = this.useInfo
        //this.searchData();
    },
    mounted(){
        // 鼠标进入公司logo上传地方
        $(document).on('mouseenter','#Logo #file2', function () {
            var imgSrc = $(this).siblings('#companyLogo').attr('src')
            if(imgSrc.indexOf('http') !== -1){
                $(this).next().show()
            }
        })
        // 鼠标移出公司logo上传地方
        $(document).on('mouseleave','#Logo #file2', function () {
            $(this).next().hide()
        })
    },
    methods:{
        userPage(){
            let id = this.userData.privateUserID;
            //console.log(this.userData)
            if(id == '' || id == null){
                window.open(this.preUrlHome,'_self')
            }else{
               window.open(this.communityLink+'/person-plate?id='+id);
            }
        },
        closeAddCompany(){
            this.$store.commit('closeAddCompany');
            // console.log(this.userData.companyList)
            // if(this.userData.companyList==undefined||this.userData.companyList.length == 0){
            //     window.location = this.jumpLink
            // }
        },
        //封装 请求
        getRequest(config) {
            let pctoken =this.GetTokenFromCookie('pcToken')
            //console.log(pctoken)
            return new Promise(function (resolve, reject) {
                $.ajax({
                    async: true,
                    type: config.type,
                    url: `${process.env.VUE_APP_API_URL}${config.url}`,
                    contentType: "application/json",
                    dataType: "json",
                    data: JSON.stringify(config.data),
                    beforeSend: function (request){
                        //console.log(Cookie.get('AsscessToken'))
                        request.setRequestHeader("authorization", pctoken )
                    },
                    success: function (res) {
                        resolve(res)
                    },
                    error: function (err) {
                        reject(err)
                    }
                });
            })
        },
        // 封装请求（文件上传请求）
        getRequestField(config) {
            //console.log(config)
            let pctoken =this.GetTokenFromCookie('pcToken')
            return new Promise(function (resolve, reject) {
                $.ajax({
                    async: true,
                    type: config.type,
                    url: `${process.env.VUE_APP_API_URL}${config.url}`,
                    data: config.data,
                    processData: false,
                    contentType: false,
                    beforeSend: function (request){
                        request.setRequestHeader("authorization", pctoken)
                    },
                    success: function (res) {
                        resolve(res)
                    },
                    error: function (err) {
                        reject(err)
                    }
                });
            })
        },
       
       //新增企业
        async confirmNew(){           
            var shortCompanyName = $('#shortCompanyNameValue').val(); //公司简称
            var companyName = $('#companyNameValue').val();//公司全称
            var companyEmailValue = $('#companyEmailValue').val()//公司邮箱
            var userAvatarUrl = $('#companyLogo').attr('src');
            //console.log(userAvatarUrl)
            var nameReg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
          
            if(userAvatarUrl.indexOf('http') == -1) return this.$message('请上传公司logo')
            if(!companyName.trim()) return this.$message('请输入公司完整名称')
            if(!nameReg.test(companyName)) return this.$message('可用文字、数字、英文、括号和空格进行企业全称填写（长度在8-30个字区间）');
            if(!shortCompanyName.trim()) return this.$message('请输入公司简称')

            if(shortCompanyName.trim().length < 2 || shortCompanyName.trim().length > 6) return this.$message('公司简称长度在 2 到 6 个字符');
            if(!companyEmailValue.trim()){
                return this.$message('请输入邮箱');
            }else if (!this.emailReg.test(companyEmailValue)){
                return this.$message('请输入正确的邮箱');
            }
            
            var res = await this.getRequest({
                type: 'post',
                url:'/User/AddCompanyByUser',
                data: {
                    userID: '',
                    email: companyEmailValue,//公司邮箱
                    companyName,//公司全称
                    userAvatarUrl,
                    shortCompanyName,//公司简称
                    terminalType: 1
                }
            }) 
            if(res.isSuccess){
                console.log(res);
                this.$store.commit('closeAddCompany');
                this.$message({message:res.msg,type:'success'})
                // await this.searchData()
                this.$store.commit('openEditCompanyName','');
                this.whereOpenAddCompany = true;
                this.$router.go(0);
            }else{
                this.$message(res.msg);
                this.$store.commit('openAddCompany');
            }
        },

        //点击上传公司logo
        editCompanyLogo(){
            let _this = this;
            $('#file2').val('')
            $('#file2').on("change", async function () {
                var file2 =  document.getElementById('file2');//获取文件
                var newFile = file2.files[0]
                let size = newFile.size
                if(size > 2048000) return _this.$message({message:'添加失败，请上传小于2M的图片',type:'warning'})
                var newFileName = newFile.name
                let fileExpand = newFileName.slice(newFileName.lastIndexOf(".")).toLowerCase()
                var imgType = ['.jpg', '.png', '.jpeg']
                var boolean = imgType.some(function (item, index) {
                    return item == fileExpand
                })
                
                if(!boolean) return _this.$message({message:'请上传图片格式为jpg/jpeg/png',type:'warning'})
                let formData = new FormData()
                formData.append("file",newFile)
                var res = await _this.getRequestField({
                    type: 'post',
                    url: `/Common/UploadFile/V2?module=email&fileExpand=${fileExpand}`,
                    data: formData
                })
                if(res.isSuccess){
                    _this.$message({message:res.msg,type:'success'})
                    var data = res.data
                    $('#companyLogo').attr('src', data)
                    // 解决input change事件触发多次问题
                    // 上传完成重建一个新的元素
                    $('#file2').replaceWith('<input class="uploadImg2" type="file" id="file2">')
                    // 解除change事件绑定
                    $('#file2').off('change')
                    $('#file2').off('mouseenter')
                    $('#file2').off('mouseleave')
                }else{
                    _this.$message(res.msg)
                }
            })
        },   

        //点击渲染公司列表中的某一项
        workbench(companyListItem,remindUrl){
            let _this =this;
            var userId = companyListItem.userID;
            var companyName = companyListItem.companyName;
            var logoUrl = companyListItem.userAvatarUrl;
            // 判断公司名字是否为空
            if(companyName == null || companyName == ""){
                this.$store.commit('openAddCompany');
                this.$store.commit('openEditCompanyLogo',logoUrl) ;
                this.$store.commit('openEditCompanyName',userId) ;
            }
            if(companyName){
                if(remindUrl){
                    //console.log(remindUrl)
                    window.location = remindUrl;
                }else{
                    // 如何是已认证，点击跳转工作台
                    location.href = this.jumpLoginLink+"/Account/Login?token=" + this.GetTokenFromCookie('pcToken') + "&userId=" + userId;
                }
            }
        },
    }
}
</script>
<style lang="less">
.user-drawer{
    height: 100%;
}
/* 新增企业 */
.add-company{
    height: 500px;
    z-index: 1002;
    .addcom-wrap{
        text-align: center;
        .pic{
            line-height:28px;
            padding-left:117px;
            text-align:left;
            span{
                font-size: 14px;
                color:#3c3c3c;
            }
        }
        .addcom-item{
            align-items: center;
            position: relative;
            .logo{
                width: 94px;
                height: 94px;
                border-radius: 10px;
            }
            .input-block{
                display: flex;
                margin-left: 0;
            }
            .default-input{
                width: 224px;
                height: 32px;
            }
        }
        .addcom-item:nth-child(1){
            height: 154px;
            margin-bottom: 0;
            .uploadImg2{ 
                position: absolute;
                width: 94px;
                height: 94px;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                opacity: 0;
                cursor: pointer;
                z-index: 30;
                .logo{
                    margin: 30px 0;
                    width: 94px;
                    height: 94px;
                    border-radius: 10px
                }
            }
            .resetLogo-backg{
                position: absolute;
                width: 94px;
                height: 94px;
                background: #71BFFF;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
                z-index: 11;
                border-radius: 10px;
                h4{
                    color: #fff;
                    background: #385F7F;
                    height: 36px;
                    width: 100%;
                    text-align: center;
                    line-height: 36px;
                    margin-top: 58px;
                    border-radius: 0 0 10px 10px;
                }
            }
        }
        .addcom-item:nth-child(5){
            margin-bottom: 0;
        }
        .email-pic{
            width: 210px;
            margin:0 auto;
            padding:5px;
            font-size: 12px;
            line-height: 14px;
            text-align: left;
            color: #13C2C2;
        }
        .addcom-btn-box{
           margin-left: 0;
        }
    }

}

/* 选择公司 */
.company{
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  height: 442px;
  background:#fff;
  z-index: 1001;
  box-shadow: 1px 1px 50px rgba(0,0,0,0.3);
  border-radius: 10px;
  .box-head{
    background: #0089cd;
    color:#fff;
    height: 50px;
    line-height: 50px;
    display:flex;
    border-radius: 10px 10px 0 0;
    .head-title{
        font-size: 14px;
        padding: 0 0 0 30px;
        position: relative;
        margin: 0;
    }
    .close-icon{
      margin-top: 15px;
    }
  }
  .box-main{
    margin: 16px 0;
    line-height: 2;
    padding: 0;
     /* 公司信息 */
    .company-box{
        margin-top: 27px;
        max-height: 355px;
        overflow: hidden;
        overflow-y: auto;
        .company-item{
            padding: 12px 0 11px 30px;
            font-size: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            cursor: pointer;
            .com-logo{
                height: 32px !important;
                width: 32px !important;
                border-radius: 4px;
            }
            .img{
                width: 100%;
                height: 100%;
                border-radius: 4px;
                overflow: hidden;
            }
            .com-name{
                margin-left: 11px;
                color: #333333;
                font-size: 16px;
                max-width: 210px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
            .com-name:hover{
                color: #1890FF;
            }
        }
        .company-item:hover{
          background: #e6f7ff;
        }
    }
  }
}

/* 遮罩层 */
.mask{
    background-color: rgb(0, 0, 0);
    opacity: 0.3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
}
/* 弹框基础样式设置 */
.dialog{
    position: fixed;
    top:50%;
    left:50%;
    width: 450px;
    border-radius: 12px;
    background: #fff;
    transform: translate(-50%,-50%);
    box-shadow: 1px 1px 50px rgba(0,0,0,0.3);
    .close-icon{
        top: 0;
    }
}
.dialog-head{
    height: 50px;
    line-height: 50px;
    background: #0089CD;
    padding: 0;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .head-title{
        font-size: 14px;
        color:#fff;
    }
}
/* 弹框关闭按钮 */
.close-icon {
    position: absolute;
    top:-15px;
    right:15px;
    img{
        width: 16px;
        height: 16px;
        border: 1px solid #2e8ded;
        background: #2e8ded;
    }
}
.close-icon img:hover{
    cursor: pointer;
    border: 1px solid #208ce3;
    background: #208ce3;
}
/* 弹框输入框基础样式 */
.addcom-item{
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    color:#3c3c3c;
    .form-label{
        width: 80px;
        text-align: right;
    }
    .input-inline{
        display: flex;
        margin-left: 10px;
        width: 224px;
        i{
            position: absolute;
            color: #BFBFBF;
            margin-left: 198px;
        }
    }
    /* 获取验证码样式 */
    .color-blue{
        font-size: 14px;
        font-style: normal;
        margin-left: 145px !important;
        cursor: pointer;
    }
    .color-blue:hover{
        color:#0089CD !important;
    }
    .color-gray{
        color: #E0E0E0 !important;
        cursor: auto;
        text-align: center;
        font-style:inherit;
    }
}
/* 弹框确认、取消按钮设置 */
.addcom-btn-box{
    margin-left: 70px;
    .default-btn{
        min-width: 65px;
        height: 32px;
        line-height: 32px;
        margin: 30px 6px;
    }
}
/* 输入框 */
.default-input{
    display: block;
    width: 224px;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-color:#BFBFBF;
    border-radius: 2px;
    padding-left: 10px;
    outline:0;
}
.default-input:focus{
    border-color:#71BFFF;
}
/* 按钮 */
.default-btn{
    display: inline-block;
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
}
.default-btn:hover{
    opacity: 0.8;
}
.btn-primary {
    border: 1px solid #C9C9C9;
    background-color: #fff;
    color: #555;
}
.btn-primary:hover{
    color:#40A9FF;
    border-color: #40A9FF;
}
.btn-normal {
    color:#fff !important;
    background-color: #0089CD;
}
.btn-normal:hover{
    background-color:#1E9FFF;
}
/* 字体图标 */
@font-face {
    font-family: "iconfont"; /* Project id 2739610 */
    src: url('//at.alicdn.com/t/font_2739610_yovty13gddl.woff2?t=1628824588503') format('woff2'),
         url('//at.alicdn.com/t/font_2739610_yovty13gddl.woff?t=1628824588503') format('woff'),
         url('//at.alicdn.com/t/font_2739610_yovty13gddl.ttf?t=1628824588503') format('truetype');
}
.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}
.iconfont:hover{
    color: #40A9FF !important;
} 
.icon-suo1:before {
    content: "\e60a";
}
.icon-xiugaiziliao:before {
    content: "\e7e0";
}
.icon-biyan:before {
    content: "\e611";
}
.icon-yanjing:before {
    content: "\e6f3";
}

</style>